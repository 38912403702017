import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_action_sheet = _resolveComponent("van-action-sheet")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "toolbar-button-more",
      type: "default",
      size: "small",
      round: "",
      style: {"float":"left"},
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = true))
    }, " 更多操作 "),
    _createVNode(_component_van_action_sheet, {
      show: _ctx.visible,
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
      onSelect: _ctx.onActionSheetSelect,
      "cancel-text": "取消",
      "close-on-click-action": ""
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["show", "onSelect"])
  ], 64))
}