
import { defineComponent, defineProps, defineEmits, ref } from "vue";
import fetchApi from "@/api";
import { eventBus, getUrlParams, store } from "@/utils";
import ProcessType from "@/enums/processType";
import { Dialog, Toast } from "vant";
import { TaskItemProps } from "@/views/type";

export default defineComponent({
  name: "RejectButton",
  props: {
    block: {
      type: Boolean,
      default: false,
    },
    quickApprove: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["reload"],
  setup(props, context) {
    const visible = ref(false);
    const message = ref("");
    const errorMessage = ref("");
    const isAddsigner = ref(false);

    const search = getUrlParams();
    let reqId = search.get("processInstanceId") || "";

    const show = (id: string) => {
      visible.value = true;
      reqId = id;
    };

    const clear = () => {
      message.value = "";
      errorMessage.value = "";
    };
    const openDialog = () => {
      const taskItem = store.get("taskItem") as unknown as TaskItemProps;
      if (taskItem) {
        isAddsigner.value = taskItem.taskType === "addsigner";
      }
      visible.value = true;
    };
    const doReject = (action: string) => {
      if (action === "confirm") {
        if (message.value === "") {
          errorMessage.value = "审批意见不能为空";
          return false;
        }
        const taskItem = store.get("taskItem") as unknown as TaskItemProps;
        if (taskItem) {
          const { taskId, taskType, assignee } = taskItem;
          const params: any = {
            processInstanceIdList: [reqId],
            systemCode: "oa-m",
            submitComment: message.value,
            submitResult: ProcessType.REJECT,
          };
          let request = fetchApi.approve;
          if (taskType === "proxy") {
            // 代理审批
            request = fetchApi.proxyApprove;
            params.assignee = assignee;
          }
          return fetchApi
            .beforeApprove()
            .data({
              pid: reqId,
              tid: taskId,
              submitResult: ProcessType.REJECT,
            })
            .silent(true)
            .send("POST")
            .then((res) => {
              if (!res.data.success) {
                Dialog.alert({
                  title: "审批失败",
                  message: res.data.message,
                });
                return false;
              }
              return request()
                .data(params)
                .send("POST")
                .then(() => {
                  Toast.success("驳回成功");
                  clear();
                  if (!props.quickApprove) {
                    eventBus.emit("goBack");
                  }
                  eventBus.emit("judgeApprove");
                  return true;
                })
                .catch(() => {
                  return false;
                });
            })
            .catch(() => {
              Dialog.alert({
                title: "审批失败",
                message: "当前任务已取消或者已完成",
              }).then(() => {
                visible.value = false;
                if (!props.quickApprove) {
                  context.emit("reload");
                } else {
                  eventBus.emit("judgeApprove");
                }
              });
              return false;
            });
        } else {
          Toast.fail("未查询到流程实例信息");
        }
      }
      clear();
      return true;
    };
    return {
      visible,
      isAddsigner,
      message,
      errorMessage,
      openDialog,
      doReject,
      show,
    };
  },
});
