import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "proc-alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_dialog = _resolveComponent("van-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_button, {
      clstag: "h|keycount|approve_1648190968616|3",
      class: "toolbar-button",
      type: "default",
      size: "small",
      round: !_ctx.block,
      block: _ctx.block,
      onClick: _ctx.openDialog
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 驳回 ")
      ]),
      _: 1
    }, 8, ["round", "block", "onClick"]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_van_dialog, {
        show: _ctx.visible,
        "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
        title: "审批意见",
        "show-cancel-button": "",
        teleport: "body",
        beforeClose: _ctx.doReject
      }, {
        default: _withCtx(() => [
          (!_ctx.isAddsigner)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, " 驳回操作将把申请单退回到申请人，您确定执行此操作? "))
            : _createCommentVNode("", true),
          _createVNode(_component_van_field, {
            modelValue: _ctx.message,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.message) = $event)),
            modelModifiers: { trim: true },
            rows: "2",
            autosize: { maxHeight: 100 },
            label: "",
            type: "textarea",
            maxlength: "500",
            "error-message": _ctx.errorMessage,
            placeholder: "请填写审批意见（必填）",
            "show-word-limit": ""
          }, null, 8, ["modelValue", "error-message"])
        ]),
        _: 1
      }, 8, ["show", "beforeClose"])
    ]))
  ], 64))
}