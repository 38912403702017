
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "MoreButton",
  setup() {
    const visible = ref(false);
    const onActionSheetSelect = (action: any) => {
      // 处理 ActionSheet 选项被选中的逻辑
    };
    return {
      visible,
      onActionSheetSelect,
    };
  },
});
