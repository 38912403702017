import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_dialog = _resolveComponent("van-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_button, {
      clstag: "h|keycount|approve_1648190968616|3",
      class: "toolbar-button",
      type: "danger",
      size: "small",
      round: !_ctx.block,
      block: _ctx.block,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = true))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 批准 ")
      ]),
      _: 1
    }, 8, ["round", "block"]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_van_dialog, {
        show: _ctx.visible,
        "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event)),
        title: "审批意见",
        "show-cancel-button": "",
        teleport: "body",
        beforeClose: _ctx.doApprove
      }, {
        default: _withCtx(() => [
          _createVNode(_component_van_field, {
            modelValue: _ctx.message,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.message) = $event)),
            rows: "2",
            autosize: { maxHeight: 100 },
            label: "",
            type: "textarea",
            maxlength: "500",
            placeholder: "请填写审批意见（非必填）",
            "show-word-limit": ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["show", "beforeClose"])
    ]))
  ], 64))
}