enum ProcessType {
  DRAFT = "0", //草稿
  APPROVE = "1",
  REFUSE = "2",
  REJECT = "3",
  CANCEL = "4",
  COMPLETE = "5",
}

export default ProcessType;
