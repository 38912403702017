
import { eventBus, getUrlParams, store } from "@/utils";
import fetchApi from "@/api";
import { TaskItemProps } from "@/views/type";
import ProcessType from "@/enums/processType";
import { Dialog, Toast } from "vant";
import { ref, defineComponent } from "vue";

export default defineComponent({
  name: "ApproveButton",
  props: {
    block: {
      type: Boolean,
      default: false,
    },
    quickApprove: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["reload"],
  setup(props, context) {
    const search = getUrlParams();
    let reqId = search.get("processInstanceId") || "";

    const visible = ref(false);
    const message = ref("");

    const show = (id: string) => {
      visible.value = true;
      reqId = id;
    };

    const clear = () => {
      message.value = "";
    };

    const doApprove = async (action: string) => {
      if (action !== "confirm") {
        clear();
        return true;
      }
      const taskItem = store.get("taskItem") as unknown as TaskItemProps;
      if (!taskItem) {
        Toast.fail("未查询到流程实例信息");
        return false;
      }
      const { taskType, isMustInput, taskId, assignee } = taskItem;
      if (taskType !== "addsigner" && isMustInput) {
        Dialog.alert({
          message:
            "此流程需要您进行表项填写，请在PC端登录ERP-流程中心完成审批。",
        });
        return false;
      } else {
        const params: any = {
          processInstanceIdList: [reqId],
          systemCode: "oa-m",
          submitComment: message.value,
          submitResult: ProcessType.APPROVE,
        };
        let request = fetchApi.approve;
        if (taskType === "proxy") {
          // 代理审批
          request = fetchApi.proxyApprove;
          params.assignee = assignee;
        }
        return fetchApi
          .beforeApprove()
          .data({
            pid: reqId,
            tid: taskId,
            submitResult: ProcessType.APPROVE,
          })
          .silent(true)
          .send("POST")
          .then(() => {
            return request()
              .data(params)
              .send("POST")
              .then(() => {
                Toast.success("审批成功");
                clear();
                if (!props.quickApprove) {
                  eventBus.emit("goBack");
                }
                eventBus.emit("judgeApprove");
                return true;
              })
              .catch(() => {
                return false;
              });
          })
          .catch(() => {
            Dialog.alert({
              title: "审批失败",
              message: "当前任务已取消或者已完成",
            }).then(() => {
              visible.value = false;
              if (!props.quickApprove) {
                context.emit("reload");
              } else {
                eventBus.emit("judgeApprove");
              }
            });
            return false;
          });
      }
    };
    return {
      visible,
      message,
      doApprove,
      show,
    };
  },
});
